.energy-profile-page {
    /* top, bottom, right, left */
    padding: 0px 20px 20px 35px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .explanation {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .categories {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .category {
    width: 18%;
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f0f8ff;
    min-height: 200px;
    transition: background-color 0.3s;
  }
  
  .category:hover {
    background-color: #e0e0e0;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
    cursor: move;
    font-size: 0.9em;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .floating-cards {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  