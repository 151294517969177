/*
purple-words: 6b8afd
*/

.container {
  position: relative;
  /* border: 1px solid rgba(209, 186, 223, 0.298); */
  background-color: #212328;
}

.coachIntro {
  color: white;
  /* border: 1px solid red; */
  background-color: #2e333d;
  max-width: 435px;
  margin: 30px auto;
  border-radius: 20px;
  padding: 20px;
}
.coachName {
  /* all: initial; */
  color: white;
  text-align: center;
  margin: 15px;
}

.coachIntroImg {
  width: 100px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.imgBox {
  display: flex;
  justify-content: center;
}

.messageWindow {
  box-sizing: border-box;
  /* border: 1px solid lightgrey; */
  padding: 10px 20px;
  height: 600px;
  margin: 0;
  overflow-y: auto;
  /* padding-bottom: 80px; */
}

.message {
  /* height: 200px; */
  max-width: fit-content;
  margin: 10px;
  margin-bottom: 3px;
  border-radius: 30px;
  padding: 20px;
  width: 60%;
}
.message > p {
  margin: 0;
}
.time {
  color: rgb(176, 176, 176);
  font-size: 12px;
  margin: 0 30px;
}

.user {
  background-color: #6b8afd;
  color: #e5effd;
}
.bot {
  background-color: #2e333d;
  /* color: rgb(176, 176, 176); */
  color: #d0d3d8;
}

.lastMessage {
  transition: opacity 1.1s ease;
}
