html,
body,
body > div {
  /* the react root */
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

h2 {
  margin: 0;
  font-size: 16px;
}

h5 {
  margin: 0;
  font-size: medium;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  /* margin: 1.5em 0; */
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.app-sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.app-main {
  flex-grow: 1;
  padding: 3em;
}

.chat-input {
  display: flex;
  width: 100%;
  margin: 20px 0px;
}

.chat-input button {
  flex-shrink: 0;
}

.chat-input input {
  flex-grow: 1;
  border: 1px solid grey;
  border-radius: 10px;
}

.chat-text-area {
  white-space: pre-line;
  background-color: white;
  padding: 10px;
  overflow-y: auto;
  border: 1px solid grey;
  border-radius: 10px;
  height: 70vh;
}

.scrollable-task-list {
  max-height: 100vh; /* Adjust as needed */
  overflow-y: auto;
}

.task-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgray;
}
