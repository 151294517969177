.sendBar {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
  padding: 7px 0;
  /* backdrop-filter: blur(4px); */
  /* background-color: rgb(255, 255, 255); */
  background: linear-gradient(0deg, #212328, transparent);
}
.sendBar button {
  all: unset;
  /* display: inline; */
  width: 15%;
  background-color: #6b8afd;
  color: #e5effd;
  /* padding: 30px; */
  font: inherit;
  cursor: pointer;
  text-align: center; /* Ensures text alignment is centered */
  border-radius: 18px; /* Rounded corners */
}

.sendTextField {
  box-sizing: border-box;
  outline: none;
  width: 80%;
  padding: 20px 30px;
  /* border: 1px solid #ccc; */
  /* Border color and thickness */
  border-radius: 18px; /* Rounded corners */
  font-family: Arial, sans-serif; /* Font type */
  font-size: 16px; /* Font size */
  line-height: 1.6;
  background-color: #2e333d;
  color: #e5effd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow for depth */
  resize: none; /* Disable manual resizing */
  overflow: auto; /* Hide scrollbars */
  /* min-height: 100px; */
  max-height: 80px;
  scroll-behavior: smooth;
}
