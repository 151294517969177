.container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.bubbles {
  display: flex;
  align-items: center;
}

.bubble {
  width: 9px;
  height: 9px;
  background-color: #b6b6b6; /* iMessage blue color */
  border-radius: 50%;
  margin: 0 3px;
  animation: bounce 1.2s infinite ease-in-out;
}

/* Keyframes for bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-3px);
  }
  /* 40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  } */
}

/* Add delays for each bubble */
.bubble:nth-child(2) {
  animation-delay: 0.3s;
}

.bubble:nth-child(3) {
  animation-delay: 0.6s;
}
