/* Define theme variables for consistent styling */
:root {
  --color-primary: #4a90e2; /* Primary theme color */
  --color-secondary: #f7f7f7; /* Secondary theme color */
  --color-task-yellow: #ffc107; /* Pending approval task */
  --color-task-red: #dc3545; /* Blocked task */
  --color-task-cyan: #0dcaf0; /* In-progress task */
  --color-task-blue: #007bff; /* Not started task */
  --color-task-green: #198754; /* Completed task */
  --font-family: 'Roboto', sans-serif; /* Modern font */
  --task-card-padding: 0px 20px 0px 5px; 
  --task-card-margin: 16px;
}
/* Light Theme */
.light-theme {
  --task-item-background-color: #ffffff; /* White background for light theme */
}

/* Dark Theme */
.dark-theme {
  --task-item-background-color: #333333; /* Dark background for dark theme */
}

/* General styles */
body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

/* TaskList container */
.task-list-container {
  /* margin: var(--task-card-margin); */
  max-height: 100%;
  overflow-y: auto;
  padding: var(--task-card-padding);
  width: 100%;
}

/* Task header styling */
.task-header {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.task-header-title {
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
}

.task-card:hover {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Task card title */
.task-card .ant-card-head-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  padding: 0;
}

.task-card .ant-card:not(.ant-card-bordered) {
  /* no box shadow */
  box-shadow: none;
}

.task-card .ant-card-head {
  padding: 0;
}

/* Task card body */
.task-card .ant-card-body {
  padding: 0;
}

/* Task item styling */
.task-item {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
}

.task-item:last-child {
  /* border-bottom: none; */
}

/* Task item title and content */
.task-item-title {
  font-size: 1rem;
  font-weight: 500;
}

.task-item-description {
  color: #666;
}

/* Task item buttons container */
.task-item-buttons {
  display: flex;
  gap: 8px;
}

/* Task status colors */
.task-item.pending-approval {
  border-left: 4px solid var(--color-task-yellow);
}

.task-item.blocked {
  border-left: 4px solid var(--color-task-red);
}

.task-item.in-progress {
  border-left: 4px solid var(--color-task-cyan);
}

.task-item.not-started {
  border-left: 4px solid var(--color-task-blue);
}

.task-item.completed {
  border-left: 4px solid var(--color-task-green);
}

/* Add task button styling */
.add-task-button {
  margin-bottom: var(--task-card-padding);
}

.add-task-button img {
  margin-right: 8px;
}

/* Task card status title colors */
.task-card-status-title.pending-approval {
  color: #ffc107; /* Yellow */
}

.task-card-status-title.blocked {
  color: #dc3545; /* Red */
}

.task-card-status-title.in-progress {
  color: #0dcaf0; /* Cyan */
}

.task-card-status-title.not-started {
  color: #007bff; /* Blue */
}

.task-card-status-title.completed {
  color: #198754; /* Green */
}

/* Task card styling */
.task-card {
  /* border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0px 16px; */
  box-shadow: none !important;
  background-color: transparent;
  border: none !important;
  /* transition: box-shadow 0.3s ease;
  max-width: 100%; /* Ensure the card takes up the full available width */
  /* overflow: hidden; Hide any overflowing content */
}

/* Task content styling */
.task-content {
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
  overflow: hidden; /* Hide overflow */
}

/* Task item styling */
.task-item {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  overflow: hidden; /* Hide overflow */
}

.task-item:last-child {
  /* border-bottom: none; */
}

.ant-collapse-header {
  font-size: 1.25rem;
  font-weight: 500;
  background-color: transparent !important;
}

/* Remove background color from Collapse Panel content */
.ant-collapse-content-box {
  background-color: transparent !important;
}

.collapse-card {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.collapse-card .ant-collapse-header,
.collapse-card .ant-collapse-content-box,
.collapse-card .ant-collapse-content,
.collapse-card .ant-collapse-item {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.task-list-container.small-container .content p,
.task-list-container.small-container .truncate,
.task-list-container.small-container .content-title, 
.task-listcontainer.small-container .content-first-container{
  font-size: 0.9rem;
  line-height: 1.4;
  white-space: normal;
}
.task-listcontainer.small-container .content-first-container .content-title,
.task-list-container.small-container .content-title{
  max-width: calc(100vw - 325px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.task-list-container.small-container .due-date,
.task-list-container.small-container .energy-delta-container {
  display: none;
}
