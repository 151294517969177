/*
main-bg: 212328
message-bg: 2e333d
grey-words: a5aab6
white-words: fdfdfd
*/

.container {
  /* border: 4px solid brown; */
  background-color: #212328;
  /* padding: 30px; */
  height: 600px;
  box-sizing: border-box;
}

.title {
  text-align: center;
  margin-top: 100px;
  color: #fdfdfd;
}
@media (max-width: 594px) {
  .title {
    margin-top: 40px;
    font-size: 20px;
  }
}

.coachTiles {
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid red; */
  justify-content: center;
  color: #fdfdfd;
  max-width: 640px;
  margin: 0 auto;
  min-width: 240px;
}

.tile {
  background-color: grey;
  margin: 10px;
  width: 120px;
  height: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
@media (max-width: 715px) {
  .tile {
    margin: 10px;
    width: 90px;
    height: 120px;
  }
}

.coachName {
  /* font-size: 30px; */
  text-align: center;
  margin-top: 10px;
  line-height: 1;
  color: white;
  font-weight: 600;
}

.imgBox {
  display: flex;
  justify-content: center;
}
.tile img {
  width: 40px;
  border-radius: 20%;
  /* overflow: hidden; */
}
