.container {
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
  padding: 15px;
  box-sizing: border-box;
}

.searchTextField {
  box-sizing: border-box;
  outline: none;
  padding: 7px;
  width: 100%;
  /* margin: 5px; */
  height: 35px;
  /* border: 1px solid #ccc; */
  /* Border color and thickness */
  border-radius: 10px; /* Rounded corners */
  font-family: Arial, sans-serif; /* Font type */
  font-size: 14px; /* Font size */
  /* line-height: 1.6; */
  background-color: #2e333d;
  color: #e5effd;
  resize: none;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  padding-left: 40px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.searchBox {
  position: relative;
  width: 80%;
}
.searchBoxImg {
  position: absolute;
  top: 3px;
  left: 1px;
  padding-left: 12px;
  width: 30px;
  height: 30px;
  background-color: #2e333d;
  border-radius: 7px;
  /* border: 1px solid red; */
}

.pencil {
  width: 36px;
  height: 36px;
  /* border: 1px solid red; */
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
.pencil:hover {
  background-color: #2e333d;
  display: block;
}
