.container {
  /* border: 3px solid grey; */
  /* padding: 30px; */
  display: flex;
  max-width: 1020px;
  margin: 0px auto;
  background-color: #212328;
  margin-top: 70px;
}

.container > *:nth-child(1) {
  flex: 0.5;
  max-width: 300px;
}
.container > *:nth-child(2) {
  flex: 1;
}

/* .container * {
  box-sizing: border-box;
} */
