/*
main-bg: 212328
message-bg: 2e333d
grey-words: a5aab6
white-words: fdfdfd
*/

.container {
  /* background-color: #676d7d; */
  background-color: #212328;
  /* border: 4px solid green; */
  height: 600px;
  overflow-y: auto;
  scrollbar-width: thin; /* Options: auto, thin, or none */
  scrollbar-color: #2e333d #f1f1f1; /* Thumb color and track color */
  min-width: 280px;
}

.noThreads {
  color: #a5aab6;
  /* border: 1px solid red; */
  pointer-events: none;
}
.noThreads p {
  text-align: center;
  line-height: 1.4;
  user-select: none;
}

.thread {
  /* border: 1px solid red; */
  display: flex;
  border-radius: 20px;
  /* background-color: #383e4a; */
  margin: 7px;
  position: relative;
  cursor: pointer;
}
.x {
  z-index: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #a5aab6;
  border-radius: 3px;
  padding: 2px 6px;
  /* background-color: white; */
  background-color: #212328;
}
.xOn {
  background-color: #a5aab6;
  color: #212328;
}

.imgBox {
  min-width: 60px;
  height: 60px;
  /* border: 1px solid grey; */
  border-radius: 15px;
  overflow: hidden; /* Hide any overflow */
  margin: 15px;
  margin-right: 12px !important;
}
.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.body {
  /* border: 1px solid red; */
  flex: 1;
}

.body p {
  margin: 0;
  padding-right: 6px;
}

.title {
  /* border: 1px solid orange; */
  color: #fdfdfd;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}
.subtitle {
  font-size: 12px;
  color: #a5aab6;
  line-height: 1.2;
}
.updatedAt {
  /* border: 1px solid purple; */
  font-size: 9px;
  text-align: right;
  color: #a5aab6;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 16px;
  margin-bottom: 4px;
}
.on {
  background-color: #2e333d;
}
