/* Chat card styling */
.chat-card {
  height: 92vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chat-card .ant-card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1 1;
  overflow-y: auto;
}

/* Chat list styling */
.chat-list {
  flex: 1;
  padding: 12px; /* Padding inside the list */
  overflow-y: auto; /* Allow scrolling */
}

/* Chat list item styling */
.chat-list-item {
  /* margin-bottom: 12px; Add spacing between each message */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Common bubble styling */
.chat-bubble {
  padding: 8px 12px; /* Padding inside the bubble */
  border-radius: 12px; /* Rounded corners for the bubble */
  max-width: 70%; /* Limit bubble width */
  display: inline-block; /* Inline block to fit content */
  white-space: pre-wrap; /* Allow wrapping of text */
  word-wrap: break-word; /* Wrap words if necessary */
}

/* User bubble and icon styling */
.user-container {
  /* flex-direction: row-reverse; Place bubble and icon on the right */
  text-align: right; /* Align text to the right */
  justify-content: flex-end !important;
}

.user-bubble.user-bubble {
  background-color: #f0f0f0; /* Light gray bubble color */
  margin-left: 8px; /* Reduced margin between icon and bubble */
}

/* Assistant bubble and icon styling */
.assistant-container {
  text-align: left; /* Align text to the left */
  justify-content: start !important;
}

.assistant-bubble.assistant-bubble {
  background-color: #e6f7ff; /* Light blue bubble color */
  margin-right: 8px; /* Reduced margin between icon and bubble */
}

/* Icon styling */
.chat-icon {
  width: 36px;
  height: 36px;
  background-color: #0e436b;
  border-radius: 50%;
  margin: 0;
  justify-content: center !important;
  align-items: center;
  display: flex;
}

/* Chat input form styling */
.chat-form {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 12px 30px 12px;
}

/* Chat input styling */
.chat-input {
  flex: 1; /* Allow the input to take up available space */
  border-radius: 8px; /* Rounded corners */
  padding: 8px; /* Padding inside the input */
}

/* Chat button styling */
.chat-button {
  border-radius: 8px; /* Rounded corners */
  padding: 8px 12px; /* Padding inside the button */
  height: 36px; /* Same height as the input */
  transition: background-color 0.3s ease-in-out; /* Smooth transition */
}

/* Chat button hover effect */
.chat-button:hover {
  background-color: #40a9ff; /* Brighter blue on hover */
}

.chat-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: auto;
}

.ant-list-split .ant-list-item {
    border-bottom: none;
}