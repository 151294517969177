/* Define CSS variables for colors */
:root {
  --color-primary: #4a90e2;
  --color-success: #4caf50;
  --color-danger: #e74c3c;
  --color-warning: #f39c12;
  --color-info: #3498db;
  --body-font-family: 'DM Sans', sans-serif;
}

/* Task item styles */
.task-item {
  display: flex;
  flex-direction: row;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  border-left: 4px solid transparent;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.task-item:hover {
  -webkit-transform: scale(1.02, 1.02);
  -moz-transform: scale(1.02, 1.02);
  -ms-transform: scale(1.02, 1.02);
  -o-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
  z-index: 1;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.task-item.pending-approval {
  border-color: var(--color-warning);
}

.task-item.not-started {
  border-color: var(--color-primary);
}

.task-item.in-progress {
  border-color: var(--color-success);
}

.task-item.completed {
  border-color: var(--color-info);
}

.task-item.completed,
.task-item.in-progress,
.task-item.not-started,
.task-item.pending-approval,
.task-item.blocked {
  display: block;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
  padding: 0.5rem 1.25rem;
  min-height: 50px;
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem;
  background: #fff;
  position: relative;
  -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.content {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content h5 {
  font-weight: 600;
  margin: 0;
  color: #333;
}

.content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  margin: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.content-first-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.status {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
}

.btn-container button {
  border-radius: 4px;
  transition: all 0.3s ease;
}

.content .ant-btn-primary {
  color: #333;
}

.content .ant-btn-primary:focus, 
.content .ant-btn-primary:hover,
.content .ant-btn-success:focus,
 .content .ant-btn-success:hover,
 .content .ant-btn-info:focus,
 .content .ant-btn-info:hover{
  /* background-color: transparent !important; */
  background: none !important;
  color: hsl(141, 63%, 48%) !important;;
  /* filter:brightness(#333, 100%); */
}

.content .ant-btn-danger:focus,
.content .ant-btn-danger:hover,
.content .ant-btn-warning:hover,
.content .ant-btn-warning:focus{
  background: none !important;
  /* color: red !important; */
  /* filter:brightness(#333, 100%); */
}

.content .ant-btn-danger {
  color: hsl(39, 100%, 49%) !important;;
}

.task-body-content {
  flex-grow: 1;
  margin-bottom: 10px;
}

/* Button container styling */
.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Button styles */
.btn-container .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 60px;
  height: 36px;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 0;  
  border-radius: 4px;
}

/* Show button label on hover */
.btn-container .ant-btn:hover span {
  display: inline-block; /* Show button label on hover */
}
/* Button label styling */
.button-label {
  display: none !important; /* Initially hide the label */
}

.ant-btn-icon {
  margin-inline-end: 0px !important;
}

.task-list-container .ant-list-item {
  display: block !important;
  padding: 0 !important;
  border: none !important;
  min-width: 160px
}

.text-truncate {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-family: var(--body-font-family);
  max-width: 200px;
  color:#333
}

/* Additional styles for energy delta and due date */
.additional-details {
  display: flex;
  align-items: center;
}

.energy-delta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.energy-delta-icon {
  font-size: 1rem;
  margin: 0px 5px 0px 5px;
}

.energy-icon.green {
  color: green;
}

.energy-icon.yellow {
  color: yellow;
}

.energy-icon.red {
  color: red;
}

.energy-delta {
  font-size: 1rem;
  font-weight: bold;
}

.energy-delta {
  font-size: 1rem; /* Adjust font size */
  /* font-weight: bold; Make it bold */
  margin-right: 10px; /* Add some spacing */
  color: var(--color-primary); /* Use a primary color */
}

.due-date {
  font-size: 1rem; /* Adjust font size */
  color: #777; /* Use a muted color */
  /* font-weight: bold; */
}

.icon-button {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-button .anticon {
  font-size: 18px;
}

.button-container {
  display: flex;
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .truncate {
    max-width: 150px;
  }

  .content-title {
    max-width: calc(100vw - 325px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .due-date,
  .energy-delta-container {
    display: none;
  }
}

.content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.content-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
